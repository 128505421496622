"use client";

import * as React from "react";
import Image from "next/image";
import Link from "next/link";
import { cn } from "@/src/lib/utils"
import { useSearchParams } from "next/navigation";
import { useState, useEffect, useRef } from "react";
import { createPortalSession, useSeller } from "../lib/api/useSeller";
import { Button } from "@/src/components/ui/button";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/src/components/ui/accordion";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/src/components/ui/navigation-menu";

import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/src/components/ui/sheet";

export const navLinks = [
  {
    text: "About",
    link: "/about",
  },
  {
    text: "Blog",
    link: "/blog",
  },
  {
    text: "Pricing",
    link: "/pricing",
  },
];

export const ctaLinks = [
  {
    text: "Discord",
    link: "https://discord.gg/hKbvHAHvvU",
  },
];

const components: { title: string; href: string; description: string }[] = [
  {
    title: "TikTok virality",
    href: "/docs/primitives/alert-dialog",
    description:
      "Use SellRaze's AI tools to create viral TikTok content.",
  },
  {
    title: "Youtube Automation",
    href: "/docs/primitives/alert-dialog",
    description:
      "Automate YouTube content creation to save time and boost productivity.",
  },
  {
    title: "Faceless content",
    href: "/docs/primitives/alert-dialog",
    description:
      "Create engaging faceless videos with AI avatars and voiceovers.",
  },
  {
    title: "Instagram monetization",
    href: "/docs/primitives/alert-dialog",
    description:
      "Boost Instagram earnings with eye-catching Reels and Stories.",
  },
  {
    title: "Subtitles",
    href: "/docs/primitives/alert-dialog",
    description:
      "Add accurate subtitles quickly using AI-powered generation.",
  },
  // ... (other components)
]

const FeatureTiny = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a"> & { imageSrc?: string }
>(({ className, title, children, href = "#", imageSrc, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <Link
          ref={ref}
          href={href as string}
          className={cn(
            "block select-none rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground group",
            className
          )}
          {...props}
        >
          <div className="flex items-center space-x-2">
            {imageSrc && <Image src={imageSrc} alt={imageSrc} width={18} height={18} />}
            <span className="text-sm font-medium leading-none">{title}</span>
            <Image
              src="/rightArrow.png"
              alt="arrow"
              width={10}
              height={10}
              className="transform -translate-x-1 opacity-0 transition-all duration-300 ease-in-out group-hover:translate-x-0 group-hover:opacity-100"
            />
          </div>
        </Link>
      </NavigationMenuLink>
    </li>
  )
})
FeatureTiny.displayName = "FeatureTiny";

const Feature = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a"> & { imageSrc?: string }
>(({ className, title, children, href = "#", imageSrc, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <Link
          ref={ref}
          href={href as string}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground group",
            className
          )}
          {...props}
        >
          <div className="flex flex-row items-center space-x-4">
            {imageSrc && <Image src={imageSrc} alt={imageSrc} width={52} height={52} />}

            <div className="space-y-1">
              <div className="flex flex-row gap-1 text-sm font-medium leading-none items-center">
                {title}
                <Image
                  src="/rightArrow.png"
                  alt="arrow"
                  width={10}
                  height={10}
                  className="transform -translate-x-4 opacity-0 transition-all duration-300 ease-in-out group-hover:translate-x-0 group-hover:opacity-100"
                />
              </div>
              <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
                {children}
              </p>
            </div>
          </div>

        </Link>
      </NavigationMenuLink>
    </li>
  )
})
Feature.displayName = "Feature"

const MobileFeature: React.FC<{ href: string; imageSrc?: string; title: string; children?: React.ReactNode }> = ({ href, imageSrc, title, children }) => {
  return (
    <Link
      href={href}
      className="block w-full select-none rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground group font-sfpro"
    >
      <div className="flex flex-row justify-between items-center">
        <div className="flex items-center space-x-4">
          {imageSrc && <Image src={imageSrc} alt={title} width={52} height={52} />}
          <div className="space-y-1">
            <div className="text-sm font-medium leading-none">{title}</div>
            {children && (
              <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
                {children}
              </p>
            )}
          </div>
        </div>
        <Image
          src="/rightArrow.png"
          alt="arrow"
          width={10}
          height={10}
          className="transform -translate-x-4 opacity-0 transition-all duration-300 ease-in-out group-hover:translate-x-0 group-hover:opacity-100"
        />
      </div>
    </Link>
  );
};

const MobileFeatureTiny: React.FC<{ href: string; imageSrc?: string; title: string; children?: React.ReactNode }> = ({ href, imageSrc, title, children }) => {
  return (
    <Link
      href={href}
      className="block w-full select-none rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground group"
    >
      <div className="flex flex-row items-center justify-between w-full">
        <div className="flex items-center space-x-4">
          {imageSrc && <Image src={imageSrc} alt={imageSrc} width={18} height={18} />}
          <span className="text-sm font-medium leading-none">{title}</span>
        </div>
        <Image
          src="/rightArrow.png"
          alt="arrow"
          width={10}
          height={10}
          className="transform -translate-x-4 opacity-0 transition-all duration-300 ease-in-out group-hover:translate-x-0 group-hover:opacity-100"
        />
      </div>
    </Link>
  );
};

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [showBorder, setShowBorder] = useState(false);

  const searchParams = useSearchParams();
  const affiliate = searchParams?.get("affiliate") || "";
  const referral = searchParams?.get("referral") || "";
  const source = searchParams?.get("source") || "";
  const { seller, loading } = useSeller();
  const route = process.browser ? window.location.pathname : "";

  const navRef = useRef<HTMLDivElement>(null);
  const showNavBar = () => {
    navRef.current ? navRef.current.classList.toggle("responsive_nav") : null;
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobile]);

  useEffect(() => {
    const handleScroll = () => {
      setShowBorder(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const trackAddToCartFacebookConversion = () => {
    if (affiliate == "Facebook") {
      const pixelId = process.env.NEXT_PUBLIC_META_PIXEL_ID || "";
      const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      import("react-facebook-pixel")
        .then((module) => module.default)
        .then((ReactPixel) => {
          ReactPixel.init(pixelId, undefined, options);
          ReactPixel.track("AddToCart");
        });
    }
  };

  const trackPricingPageFacebookConversion = () => {
    if (affiliate == "Facebook") {
      const pixelId = process.env.NEXT_PUBLIC_META_PIXEL_ID || "";
      const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      import("react-facebook-pixel")
        .then((module) => module.default)
        .then((ReactPixel) => {
          ReactPixel.init(pixelId, undefined, options);
          ReactPixel.track("ViewContent");
        });
    }
  };

  const paramsArray = [];
  if (affiliate !== "") {
    paramsArray.push(`affiliate=${affiliate}`);
  }

  if (referral !== "") {
    paramsArray.push(`referral=${referral}`);
  }

  if (source !== "") {
    paramsArray.push(`source=${source}`);
  }
  const params = paramsArray.length > 0 ? `?${paramsArray.join("&")}` : "";

  const handleMenuToggle = () => {
    setIsTransitioning(true);
    setMenuOpen(!menuOpen);
    setTimeout(() => setIsTransitioning(false), 300); // Match this with your transition duration
  };

  return (
    <>
      <span className="fixed-header-spacer"></span>
      <div className="fixed-header-container">
        {/* <StockTicker></StockTicker> */}
        <header
          className={`[ launch-header ] flex items-center justify-between border-b px-4 py-2 transition-[border-color] duration-300 ease-in-out ${showBorder ? "border-gray-100" : "border-transparent"}`}
        >
          <Link href={"/" + params}>
            <picture>
              <source srcSet="/sellRazeLogo.png" media="(min-width: 600px)" height="24" width="120" />
              <Image src="/sellraze_mark.svg" width="36" height="36" alt="Sellraze landing page" />
            </picture>
          </Link>
          {isMobile && (
            <Sheet open={menuOpen} onOpenChange={setMenuOpen}>
              <SheetTrigger asChild>
                <button
                  className={`relative z-50 h-[18px] w-[18px] p-0 transition-transform duration-200 hover:scale-110 ${menuOpen ? "z-40" : "z-10"}`}
                  onClick={handleMenuToggle}
                >
                  <Image
                    src="/hamburger.png"
                    alt="Menu"
                    width={1000}
                    height={1000}
                    className={`absolute left-0 top-0 h-full w-full transition-opacity duration-300 ${menuOpen ? "opacity-0" : "opacity-100"
                      }`}
                  />
                  <Image
                    src="/close.png"
                    alt="Close"
                    width={1000}
                    height={1000}
                    className={`absolute left-0 top-0 z-50 h-full w-full transition-opacity duration-300 ${menuOpen ? "opacity-100" : "opacity-0"
                      }`}
                  />
                </button>
              </SheetTrigger>
              <SheetContent side="top" className={`w-full translate-y-20 rounded-b-2xl bg-white px-8 pb-8 font-sfpro`}>
                <Accordion type="single" collapsible className="">
                  <AccordionItem value="features">
                    <AccordionTrigger>Product</AccordionTrigger>
                    <AccordionContent className="max-h-[60vh] overflow-y-auto">
                      <ul className="w-full space-y-3">
                        <li className="w-full">
                          <p className="mb-2 border-b pb-1 pl-3 text-[10px]">FEATURES</p>
                          <div className="space-y-2">
                            <MobileFeature
                              href="https://sellraze.com/dashboard"
                              imageSrc="/metallicMagic.png"
                              title="Generate item specs"
                            >
                              Turn an image, barcode, or keyword into listings.
                            </MobileFeature>
                            <MobileFeature
                              href="https://sellraze.com/dashboard"
                              imageSrc="/metallicBulk.png"
                              title="Bulk list"
                            >
                              Add items by spreadsheet or mobile device.
                            </MobileFeature>
                            <MobileFeature
                              href="https://sellraze.com/dashboard"
                              imageSrc="/metallicComparisons.png"
                              title="Price comparisons"
                            >
                              Compare your item&apos;s value across platforms
                            </MobileFeature>
                            <MobileFeature
                              href="https://sellraze.com/dashboard"
                              imageSrc="/metallicShipping.png"
                              title="Shipping center"
                            >
                              Track active shipments across platforms.
                            </MobileFeature>
                          </div>
                        </li>
                        <li className="relative">
                          <div className="absolute inset-0"></div>
                          <div className="relative z-10">
                            <p className="mb-2 border-b pb-1 pl-3 font-sfmono text-[10px]">WHAT&apos;S NEW</p>
                            <MobileFeatureTiny imageSrc="/light-changelog.svg" href="/changelog" title="Changelog" />
                            <Link href="/dashboard" className="flex flex-col space-y-2 py-4 text-start leading-5">
                              <Image src="/whatsNew.png" alt="" height={1000} width={1000} className="h-auto w-full" />
                              <p className="px-4 text-[14px] font-medium lg:px-2">SellRaze Desktop</p>
                              <p className="font-regular px-4 text-[14px] text-neutral-400 lg:px-2">
                                Manage your inventory in bulk, automate listings, and boost your e-commerce efficiency.
                              </p>
                            </Link>
                          </div>
                        </li>
                      </ul>
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="resources">
                    <AccordionTrigger>Resources</AccordionTrigger>
                    <AccordionContent className="max-h-[60vh] overflow-y-auto">
                      <ul className="grid gap-3">
                        <MobileFeature href="https://sellraze.tolt.io/login" title="Affiliate program">
                          Earn 20% on all paid referrals to SellRaze.
                        </MobileFeature>
                        <MobileFeature href="/blog" title="Blog">
                          Find news, insights, and best practices.
                        </MobileFeature>
                        <MobileFeature href="https://discord.gg/hKbvHAHvvU" title="Community Discord">
                          Join for customer support and feedback.
                        </MobileFeature>
                        <MobileFeature href="/about" title="About">
                          Read more about our mission.
                        </MobileFeature>
                      </ul>
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="mobile">
                    <AccordionTrigger>
                      <Link href="/mobile">Mobile</Link>
                    </AccordionTrigger>
                    <AccordionContent className="max-h-[60vh] overflow-y-auto">
                      <ul className="flex w-full flex-col space-y-2">
                        <MobileFeatureTiny
                          href="https://apps.apple.com/us/app/sellraze-list-sell-earn/id6455042085?itsct=apps_box_badge&amp;itscg=30200"
                          imageSrc="/iconAppStore.png"
                          title="Download on iOS App Store"
                        />
                        <MobileFeatureTiny
                          href="https://play.google.com/store/apps/details?id=com.sellraze.scanner&pcampaignid=web_share"
                          imageSrc="/iconGooglePlay.png"
                          title="Download on Google Play"
                        />
                      </ul>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
                {/* <Link
                  href="/pricing"
                  className="align-center block items-start justify-start border-b py-3 text-start text-[18px] font-medium tracking-tight"
                >
                  Pricing
                </Link> */}
                <div className="mt-4 flex w-full flex-row space-x-4">
                  <Link
                    className="[ ss-large roundedfull ] rounded-full bg-gray-200 p-4"
                    href={"https://discord.gg/hKbvHAHvvU" + params}
                    key="https://discord.gg/hKbvHAHvvU"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src="/discord_badge.svg" alt="" height="18" width="18" />
                  </Link>
                  <Link
                    className="[ ss-large roundedfull ] rounded-full bg-gray-200 p-4"
                    href={"https://twitter.com/SellRaze" + params}
                    key="https://twitter.com/SellRaze"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src="/twitter.svg" alt="" height="18" width="18" />
                  </Link>
                  <Link
                    className="[ ss-large roundedfull ] rounded-full bg-gray-200 p-4"
                    href={"https://www.instagram.com/sellraze" + params}
                    key="https://www.instagram.com/sellraze"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src="/instagram.svg" alt="" height="18" width="18" />
                  </Link>
                </div>
                <div className="mt-16 flex flex-col space-y-4">
                  <Button
                    href="https://sellraze.com/dashboard"
                    className="w-full space-x-3 bg-lightGray font-sfpro hover:bg-lightGray/80"
                  >
                    <span className="font-sfpro-bold text-black">Log in</span>
                  </Button>
                  <Button
                    href="https://sellraze.com/dashboard"
                    className="w-full space-x-3 bg-sellRazeBlue font-sfpro hover:bg-sellRazeBlue/80"
                  >
                    <Image src="/sellraze_white_logo.png" alt="sellraze_white_logo" width={14} height={14} />
                    <span className="font-sfpro-bold text-white">Get started</span>
                  </Button>
                </div>
              </SheetContent>
            </Sheet>
          )}
          <nav className="hidden items-center lg:flex ml-32">
            <NavigationMenu className="h-full w-1/2">
              <NavigationMenuList className="space-x-2">
                <NavigationMenuItem>
                  <NavigationMenuTrigger className="text-gray-600 transition-colors duration-300 hover:text-gray-900">
                    Product
                  </NavigationMenuTrigger>
                  <NavigationMenuContent>
                    <ul className="md:w-[550px] relative grid lg:w-[650px] lg:grid-cols-[1.25fr_.75fr]">
                      <li className="row-span-3 border-r border-gray-200 p-4">
                        <p className="pb-1 font-sfmono text-[10px]">FEATURES</p>
                        <div>
                          <Feature
                            href="https://sellraze.com/dashboard"
                            imageSrc="/metallicMagic.png"
                            title="Generate item specs"
                          >
                            Turn an image, barcode, or keyword into listings.
                          </Feature>
                          <Feature href="https://sellraze.com/dashboard" imageSrc="/metallicBulk.png" title="Bulk list">
                            Add items by spreadsheet or mobile device.
                          </Feature>
                          <Feature
                            href="https://sellraze.com/dashboard"
                            imageSrc="/metallicComparisons.png"
                            title="Price comparisons"
                          >
                            Compare your item&apos;s value across platforms.
                          </Feature>
                          <Feature
                            href="https://sellraze.com/dashboard"
                            imageSrc="/metallicShipping.png"
                            title="Shipping center"
                          >
                            Track active shipments across platforms.
                          </Feature>
                        </div>
                      </li>
                      <li className="relative">
                        <div className="absolute inset-0"></div>
                        <div className="relative z-10 p-4">
                          <p className="pb-1 font-sfmono text-[10px]">WHAT&apos;S NEW</p>
                          <FeatureTiny imageSrc="/light-changelog.svg" href="/changelog" title="Changelog" />
                          {/* <Link
                            href="/dashboard"
                            className="flex flex-col space-y-3 rounded-lg py-4 text-start leading-6 transition-all duration-300 hover:bg-gray-100"
                          >
                            <Image
                              src="/whatsNew.png"
                              alt=""
                              height={1000}
                              width={1000}
                              className="h-auto w-full px-2"
                            />
                            <p className="px-4 text-[16px] font-medium">SellRaze Desktop</p>
                            <p className="font-regular px-4 text-[16px] text-neutral-400">
                              Manage your inventory in bulk, automate listings, and boost your e-commerce efficiency.
                            </p>
                          </Link> */}
                          <p className="font-regular text-[16px] text-neutral-400">
                            Manage your inventory in bulk, automate listings, and boost your e-commerce efficiency.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </NavigationMenuContent>
                </NavigationMenuItem>
                <NavigationMenuItem>
                  <NavigationMenuTrigger className="text-gray-600 transition-colors duration-300 hover:text-gray-900">
                    Resources
                  </NavigationMenuTrigger>
                  <NavigationMenuContent>
                    <ul className="md:w-[500px] grid w-[400px] p-4 lg:w-[600px] lg:grid-cols-2">
                      <Feature href="https://sellraze.tolt.io/login" title="Affiliate program">
                        Earn 20% on all paid referrals to SellRaze.
                      </Feature>
                      <Feature href="/blog" title="Blog">
                        Find news, insights, and best practices.
                      </Feature>
                      <Feature href="https://discord.gg/hKbvHAHvvU" title="Community Discord">
                        Join for customer support and feedback.
                      </Feature>
                      <Feature href="/about" title="About">
                        Read more about our mission.
                      </Feature>
                    </ul>
                  </NavigationMenuContent>
                </NavigationMenuItem>
                <NavigationMenuItem>
                  <NavigationMenuTrigger className="text-gray-600 transition-colors duration-300 hover:text-gray-900">
                    <Link href="/mobile">Mobile</Link>
                  </NavigationMenuTrigger>
                  <NavigationMenuContent className="">
                    <ul className="flex w-[510px] flex-row p-4">
                      <FeatureTiny
                        href="https://apps.apple.com/us/app/sellraze-list-sell-earn/id6455042085?itsct=apps_box_badge&amp;itscg=30200"
                        imageSrc="/iconAppStore.png"
                        title="Download on iOS App Store"
                      />
                      <FeatureTiny
                        href="https://play.google.com/store/apps/details?id=com.sellraze.scanner&pcampaignid=web_share"
                        imageSrc="/iconGooglePlay.png"
                        title="Download on Google Play"
                      />
                    </ul>
                  </NavigationMenuContent>
                </NavigationMenuItem>
                {/* <NavigationMenuItem> */}
                {/* {route !== "/tos" && route !== "/faq" && (
                    <Link href="/pricing" legacyBehavior passHref>
                      <NavigationMenuLink className={navigationMenuTriggerStyle()}>Pricing</NavigationMenuLink>
                    </Link>
                  )} */}
                {/* </NavigationMenuItem> */}
              </NavigationMenuList>
            </NavigationMenu>
          </nav>
          <div className="hidden items-center space-x-4 lg:flex">
            <Button variant="ghost" className="font-[590] text-gray-600 hover:text-gray-900" asChild>
              <Link href="/login-option">Log in</Link>
            </Button>
            <Button variant="default" className="bg-blue-600 font-[590] text-white hover:bg-blue-700" asChild>
              <Link href="/register">Sign up</Link>
            </Button>
          </div>
        </header>
      </div>
    </>
  );
};
export default Header;
